import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { ModalContainer, ModalHeaderBar, Logo } from './styling/Components';
import axios from './api/httpClient';
import styled from 'styled-components';
//import ForgotPassword from './account/ForgotPassword';

import * as firebase from 'firebase/app';
import 'firebase/analytics';
import SignIn from './account/SignIn';


ReactGA.initialize(process.env['REACT_APP_MEASUREMENT_ID']!);

type Props = {};

type State = {
  enabled: boolean;
  completed: boolean;
};

export class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {enabled: true, completed: false}
  }

  callbackOpenForgotPassword = () => {
  };

  callbackSignInSucceeded = (accountId: string) => {
    this.forceUpdate();
  };

  handleSignOutClick = async () => {
    this.forceUpdate();
  };

  callbackForgotPasswordEmailSent = () => {
  };

  downloadData = async () => {
    this.setState({ enabled: false});
    axios({
      url: 'exportData/',
      method: 'GET',
      headers: {
        'X-Firebase-Token': await firebase.auth().currentUser?.getIdToken(),
        'Accept': 'text/csv',
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link);
      link.click();
      this.setState({completed: true});
    });

  }

  render() {
    if (firebase.auth().currentUser) {
      return (
        <ModalContainer>
        <ModalHeaderBar />
        <Logo />
        <h2>Export Data</h2>
        {
          this.state.enabled
          ? <DownloadDataButton onClick={this.downloadData}>Download</DownloadDataButton>
          : this.state.completed
            ? <DownloadDataButton>Download completed</DownloadDataButton>
            : <DownloadDataButton>Please Wait - Download will start soon</DownloadDataButton>
        }

        <DownloadDataButton onClick={this.handleSignOutClick}>Signout</DownloadDataButton>
      </ModalContainer>
      );
    } else {
      return (
        <SignIn
          callbackSignInSucceeded={this.callbackSignInSucceeded}
          callbackOpenForgotPassword={this.callbackOpenForgotPassword}
        />
      );
    }
    /*
    return (
      <ForgotPassword
        callbackForgotPasswordEmailSent={this.callbackForgotPasswordEmailSent}
      />
    );
    */
  }

}


const DownloadDataButton = styled.a`
  padding-right: 15px;
  cursor: pointer;
  float: right;
`;
