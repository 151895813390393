import React from 'react';
import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { colors } from '../theme';
import imgLogo from '../resources/images/kanbana_logo_grey.png';
import imgLeft from '../resources/images/angle-left.svg';
import imgDelete from '../resources/images/times.svg';
import imgDeleteHighlighted from '../resources/images/times-highlighted.svg';

fontawesome.library.add(faTimes as any);

export const Input = styled.input`
  font-size: 18px;
  font-weight: 600;
  padding: 0.5em;
  margin: 0.5em 0 0.5em 0;
  width: 260px;
  color: black;
  background: #eeeeee;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: ${colors.buttons.default.active};
    box-shadow: 0 0 5px ${colors.buttons.default.active};
  }
`;

type InputCaptionProps = {
  status: string;
};

export const InputCaption = styled.div<InputCaptionProps>`
  color: ${props =>
    props.status === 'danger' ? colors.task.red : colors.black};
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 8px;
`;

export const SubmitButton = styled.input`
  font-size: 18px;
  font-weight: 600;
  padding: 0.5em 0 0.5em 0;
  margin: 1.5em 0 1.5em 0;
  border-radius: 5px;
  height: 40px;
  width: 280px;
  background: ${colors.task.orange};
  color: ${colors.white};
  &:focus {
    outline: none;
    border-color: ${colors.buttons.default.active};
    box-shadow: 0 0 5px ${colors.buttons.default.active};
  }
  &:hover {
    cursor: pointer;
    background: ${colors.buttons.default.highlighted};
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 10px;
  background: white;
  flex-direction: column;
  width: 400px;
  border-radius: 5px;
`;

export const ModalHeaderBar = styled.div`
  align-self: left;
  height: 30px;
`;

export const Logo = styled.div`
  height: 56px;
  width: 56px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${imgLogo});
`;

export const BackButton = styled.button`
  align-self: flex-start;
  height: 30px;
  width: 30px;
  background-color: ${colors.buttons.default.active};
  mask-image: url(${imgLeft});
  mask-repeat: no-repeat;
  border: none;
  cursor: pointer;
  &:hover {
    background: ${colors.buttons.default.highlighted};
  }
`;

//todo pri1: not placed right if a task is below the scrolling point of the stage
export const DeleteButton = styled.button`
  position: relative;
  align-self: flex-end;
  margin: 3px 3px 0px 0px;
  padding: 0px;
  height: 12px;
  width: 12px;
  background: url(${imgDelete});
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  &:before {
    position: absolute;
    content: ' ';
    top: -10px;
    right: -10px;
    left: -10px;
    bottom: -10px;
    z-index: 40;
  }
  &:hover {
    background: url(${imgDeleteHighlighted});
    background-repeat: no-repeat;
  }
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  cursor: pointer;
  height: 24px;
  margin: 0;
  width: 24px;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.75;
  }
`;

export const ModalCloseButton = (props: any) => (
  <CloseButton {...props}>
    <FontAwesomeIcon color={colors.task.orange} icon="times" size="lg" />
  </CloseButton>
);

export const Link = styled.a`
  color: ${colors.white};
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    color: ${colors.white};
  }
`;

export const TextButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => props.color || colors.task.orange};
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: .75;
  }
`;
