// @flow
import React, { ChangeEvent, Component } from 'react';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ReactGA from 'react-ga';

import service from '../api/Service';
import { ModalContainer, ModalHeaderBar, Logo } from '../styling/Components';
import { SignInViewModel } from './SignInViewModel';

type State = {
  viewModel: SignInViewModel;
};

type Props = {
  callbackSignInSucceeded: (accountId: string) => void;
  callbackOpenForgotPassword: () => void;
};

export default class SignIn extends Component<Props, State> {
  componentWillMount() {
    this.setState({ viewModel: new SignInViewModel() });
  }

  handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const viewModel = this.state.viewModel;
    // $FlowFixMe
    viewModel.email = e.target.value;
    this.setState({ viewModel: viewModel });
  };

  handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const viewModel = this.state.viewModel;
    // $FlowFixMe
    viewModel.password = e.target.value;
    this.setState({ viewModel: viewModel });
  };

  handleForgotPassword = () => {
    this.props.callbackOpenForgotPassword();
    ReactGA.event({
      category: 'password',
      action: 'forgotPasswordButtonTapped',
    });
  };

  signInCallback = (authResult: any, redirectUrl: string) => {
    const { callbackSignInSucceeded } = this.props;

    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      currentUser
        .getIdToken(true)
        .then(function(idToken) {
          service.signIn(idToken, response => {
            if (response.signInVerified) {
              callbackSignInSucceeded(response.accountId);
              ReactGA.event({
                category: 'signIn',
                action: 'signInSucceeded',
              });
            } else {
              //todo pri2: add a custom alert dialog
              alert(response.message);
              window.location.reload();
            }
          });
        })
        .catch(function(error) {
          alert(error);
        });
    }

    // User successfully signed in.
    // Return type determines whether we continue the redirect automatically
    // or whether we leave that to developer to handle.
    return false;
  };

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    //this.unregisterAuthObserver();
  }

  uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: this.signInCallback,
    },
    signInFlow: 'popup',
    signInOptions: [
      'apple.com',
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
    ],
  };

  render() {
    const config = this.uiConfig as any;

    return (
      <ModalContainer>
        <ModalHeaderBar />
        <Logo />
        <h2>Sign In</h2>
        <StyledFirebaseAuth
          key={Math.random()}
          uiConfig={config}
          firebaseAuth={firebase.auth()}
        />
      </ModalContainer>
    );
  }
}
