import axios from './httpClient';

export type SignInResponse = {
  signInVerified: boolean;
  deviceVerified: boolean;
  accountId: string;
  message?: string;
};
//todo pri2: errors should be handeled prettier and showed to the user instead of only being logged

const signIn = (
  token: string,
  success: (signInResponse: SignInResponse) => void,
) => {
  axios
    .post('SignIn', {
      Token: token,
    })
    .then(response => {
      const data = response.data;

      const signInResponse: SignInResponse = {
        signInVerified: data.SignInVerified,
        deviceVerified: data.DeviceVerified,
        accountId: data.AccountId,
        message: data.Message,
      };
      success(signInResponse);
    })
    .catch(error => {
      console.error(error);
    });
};

export type AccountResponse = {
  webTrialEndsAt?: number;
  canCopyBoard: boolean;
};


//todo pri2: think about if it would be better to handle different kind of sync jobs so that the
//result would be a full board so that it visually had the ability to incremental update?

//todo pri2: think about if it would be better to handle different kind of sync jobs so that the
//result would be a full board so that it visually had the ability to incremental update?

const sendForgotPasswordEmail = (
  email: string,
  succeded: () => void,
  failed: (message: string) => void,
) => {
  axios
    .post('SendForgotPasswordEmail', {
      Email: email,
    })
    .then(() => {
      succeded();
    })
    .catch(error => {
      if (error.response.status === 409) {
        failed(error.response.data.Message);
      } else {
        console.error(error);
      }
    });
};

//return stories with updated priority

//return stages with updated priority

export default {
  signIn,
  sendForgotPasswordEmail,
};
