// @flow
export class SignInViewModel {
  email: string;
  password: string;

  constructor() {
    this.email = '';
    this.password = '';
  }
}
