// @flow
export const colors = {
  black: '#4d4d4d',
  blue: {
    deep: 'rgb(0, 121, 191)',
    light: 'lightblue',
    lighter: '#d9fcff',
  },
  border: {
    lightGrey: '#e3e6ec',
    grey: '#848688',
  },
  shadow: 'rgba(0,0,0,0.2)',
  focusOutline: '#E2E4E6',
  darkgrey: '#777777',
  green: 'rgb(185, 244, 188)',
  white: 'white',
  purple: 'rebeccapurple',
  stage: {
    backgroundColor: 'rgba(46,46,46,0.95)',
    hover: 'rgba(40,40,40,0.95)',
    editModeBackgroundColor: 'rgba(55,55,55,0.95)',
  },
  taskOptions: ['#7f8c8d', '#d35400', '#f39c12', '#2980b9', '#2ecc71', '#c0392b', '#8e44ad', '#774936', '#16a085'],
  task: {
    red: '#bf1503',
    orange: '#f66e00',
    grey: '#aaaaaa',
  },
  menu: {
    background: '#dddddd',
    link: '#333333',
  },
  buttons: {
    default: {
      active: '#D45100',
      highlighted: '#B33D0E',
    },
    delete: {
      active: 'rgba(255,255,255,0.80)',
      highlighted: '#ffffff',
    },
  },
};

export const borderRadius = 7;

export const sizes = {
  stage: {
    width: 240,
    margin: 8,
    titlePaddingLeft: 15,
  },
  story: {
    padding: 15,
    margin: 8,
  },
};
