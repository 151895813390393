import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from './App.container';
import './index.css';

class Main extends React.Component {
  render() {
    return (
      <AppContainer path="/" />
    );
  }
}

const firebaseConfig = {
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_DEV_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<Main />, document.getElementById('root'));
